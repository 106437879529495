import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'
import debounce from 'lodash-es/debounce'

import {
  gsap
} from 'gsap'
import {
  ScrollTrigger
} from 'gsap/ScrollTrigger'
import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'

if ('ResizeObserver' in window) {
  const mainEl = document.querySelector('.site-main')
  if (mainEl) {
    const debounceDelay = 100
    const onResize = debounce(() => {
      ScrollTrigger.refresh()
    }, debounceDelay)

    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(mainEl)
  }
}

gsap.registerPlugin(ScrollTrigger)
const staggerVal = 0.2
const durationVal = 1.7

const mm = gsap.matchMedia()

document.addEventListener('DOMContentLoaded', () => {
  const gsapElements = AlphaHelpers.smartQuerySelectorAll('[data-gsap]')
  gsapElements.forEach((gsapEl) => {
    const from = AlphaHelpers.getParsedJsonAttribute(gsapEl, 'data-gsap-from')
    const to = AlphaHelpers.getParsedJsonAttribute(gsapEl, 'data-gsap-to')

    if (from && to) {
      gsap.fromTo(gsapEl, from, to)
    } else if (from) {
      gsap.from(gsapEl, from)
    } else if (to) {
      gsap.to(gsapEl, to)
    }
  })
})


document.addEventListener('DOMContentLoaded', () => {
  initGlobalsAnimations()
  // wordsShadow()
})


function initGlobalsAnimations() {
  /*
 *Bloc intro

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.title-block', {
      xPercent: 20
    }, {
      xPercent: 15,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })
  */

  // Bloc 1

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.first-block', {
      x: -100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-first-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.first-block',
        scrub: 1
      }
    })
  })

  // Bloc 2

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.second-block', {
      x: 100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.second-block',
        scrub: 1
      }
    })
  })

  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-second-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.second-block',
        scrub: 1
      }
    })
  })

  // Bloc 3

  mm.add('(min-width: 1200px)', () => {
    gsap.fromTo('.third-block', {
      x: -100
    }, {
      x: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.third-block',
        // start: 'top center',
        // end: 'bottom center',
        scrub: 1
      }
    })
  })


  mm.add('(max-width: 1199px)', () => {
    gsap.fromTo('.img-third-block', {
      scale: 1
    }, {
      scale: 1.5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.third-block',
        scrub: 1
      }
    })
  })


  // SVG
  AlphaFilters.addAction('svgUniqId.element.parsed', (svgEl) => {
    const waveEl1 = svgEl.querySelector('[data-name="wave-1"]')
    const waveEl2 = svgEl.querySelector('[data-name="wave-2"]')
    const filigrane3El = svgEl.querySelector('[data-name="elem-1"]')
    // if (waveEl1 && waveEl2) {
    //   setTimeout(() => {
    //     gsap.from(waveEl1, {
    //       scale: 1.1,
    //       duration: 1,
    //       ease: 'none',
    //       scrollTrigger: {
    //         trigger: waveEl1,
    //         start: 'top bottom', // the default values
    //         end: 'top center',
    //         scrub: 1
    //       }
    //     })
    //     gsap.from(waveEl2, {
    //       scale: 1.1,
    //       duration: 2,
    //       ease: 'none',
    //       scrollTrigger: {
    //         trigger: waveEl1,
    //         start: 'top bottom', // the default values
    //         end: 'top center',
    //         scrub: 1
    //       }
    //     })
    //   }, 10)
    // }
    if (waveEl1 && waveEl2) {
      setTimeout(() => {
        gsap.from('svg[data-name="filigrane-wave"]', {
          scrollTrigger: {
            trigger: 'svg[data-name="filigrane-wave"]',
            start: 'top bottom', // the default values
            end: 'bottom top',
            toggleClass: 'active',
            scrub: 1
          }
        })
      }, 10)
    }
    if (filigrane3El) {
      setTimeout(() => {
        gsap.from('svg[data-name="filigrane-3"]', {
          scrollTrigger: {
            trigger: 'svg[data-name="filigrane-3"]',
            start: 'top bottom', // the default values
            end: 'bottom top',
            toggleClass: 'active',
            scrub: 1
          }
        })
      }, 10)
    }
  })


  // gsap.fromTo('.watermark-1', {
  //   scale: 0.6,
  //   xPercent: -20,
  // }, {
  //   scale: 1.1,
  //   xPercent: 0,
  //   yPercent: -10,
  //   ease: 'none',
  //   scrollTrigger: {
  //     trigger: '.watermark-1',
  //     scrub: 1
  //   }
  // })

  gsap.fromTo('.watermark-news', {
    scale: 0.8,
    xPercent: -20,
    rotate: -20
  }, {
    scale: 1.1,
    xPercent: 0,
    yPercent: -10,
    rotate: 0,
    ease: 'none',
    scrollTrigger: {
      trigger: '.watermark-news',
      scrub: 1
    }
  })
}

// gsap.timeline()
//   .delay(1)

